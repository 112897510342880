//************************************************************************//
// Default: Webkit, moz, spec
// Example: @include prefixer(border-radius, $radii, $o: true);
//************************************************************************//
@mixin prefixer ($property, $value,
                 $webkit: true,
                    $moz: true,
                     $ms: false,
                      $o: false,
                   $spec: true) {
  @if $webkit { -webkit-#{$property}: $value; }
  @if $moz    {    -moz-#{$property}: $value; }
  @if $ms     {     -ms-#{$property}: $value; }
  @if $o      {      -o-#{$property}: $value; }
  @if $spec   {         #{$property}: $value; }
}
