// Micro clearfix provides an easy way to contain floats without adding additional markup
//
// Example usage:
//
//    // Contain all floats within .wrapper
//    .wrapper {
//      @include clearfix;
//      .content,
//      .sidebar {
//        float : left;
//      }
//    }

@mixin clearfix {
  zoom: 1;

  &:before,
  &:after {
    content: "";
    display: table;
  }

  &:after {
    clear: both;
  }
}

// Acknowledgements
// Micro clearfix: [Nicolas Gallagher](http://nicolasgallagher.com/micro-clearfix-hack/)
