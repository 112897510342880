// Box-Shadow Mixin Requires Sass v3.1.1+
@mixin box-shadow ($shadow-1,
  $shadow-2: false, $shadow-3: false,
  $shadow-4: false, $shadow-5: false,
  $shadow-6: false, $shadow-7: false,
  $shadow-8: false, $shadow-9: false)
  {
    $full: compact($shadow-1, $shadow-2, $shadow-3, $shadow-4,
                   $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9);

    @include prefixer(box-shadow, $full, webkit, not moz);
}
